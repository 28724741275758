<template>
    <div class="col-md-6 col-12 d-flex flex-wrap align-items-center justify-content-center parceiros">
        <loading-ajax v-if="loading"></loading-ajax>
        <div v-else class="logo-parceiro" v-for="(item) in researchCenters" :key="item.id">
            <a :href="item.urlSite" target="_blank">
                <img :src="item.urlImage" alt="">
            </a>
        </div>
    </div>
</template>

<script>
import api from '@/services/api.js'


export default {
    components: {
    },
    data() {
        return {
            researchCenters: [],
            loading:true
        }
    },
    async created() {

        var t = this
        await api.get('/partnerEntities').then(response => (
            t.researchCenters = response.data.data
        )).finally(()=>{this.loading=false})
    },
    methods: {

    }
}
</script>

<style>
    .parceiros {
        margin: 1.5rem;
    }
    .parceiros img {
        transition: transform .1s;
        width: 75%
    }
    .parceiros img:hover {
        transform: scale(1.2)
    }
    .logo-parceiro {
        width: 12%
    }
    @media only screen and (max-width: 575px) {
        .logo-parceiro {
            width: 25%;
        }
    }
</style>